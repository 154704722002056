import React from "react";
import { Col, Container, Row } from "reactstrap";
import Image from "next/image";

const MasterBanner = ({ title, classes, bannerUrl, title1 }) => {
  const [one, two] = (title || "").split(".");

  return (
    <div style={{ position: "relative" }}>
      <div
        className={`home ${classes ? classes : "text-center"}`}
        style={{
          position: "relative",
          height: "350px",
          backgroundColor: "color.hex",
        }}
      >
        <Image
          src={bannerUrl}
          alt="Banner Image"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          quality={80}
          priority
        />

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))",
            zIndex: 1,
          }}
        ></div>

        <Container style={{ position: "relative", zIndex: 2 }}>
          <Row>
            <Col>
              <div className="slider-contain">
                <div>
                  <h2>{title1 || one}</h2>
                  <br />
                  <h4 style={{ marginBottom: 150 }}>{two}</h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MasterBanner;
