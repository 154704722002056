import React, { Fragment, useState, lazy, Suspense } from "react";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import getConfig from "next/config";
import MasterBanner from "./MasterBanner";
import SearchHeader from "../../../../components/headers/seller/searchHeader";
import vars from "../../../../helpers/utils/vars";

const SearchOverlay = lazy(() =>
  import("../../../../components/modals/SearchOverlayModal")
);

const { publicRuntimeConfig } = getConfig();
const contentsUrl = `${publicRuntimeConfig.CONTENTS_URL}`;

const Banner = (props) => {
  const { searchBar = true } = props;
  const [searchModalShow, setSearchModalShow] = useState(false);
  const [searchParams, setSearchParams] = useState({
    searchText: "",
    id: "",
    country: "",
  });
  const isTabletOrMobile = useMediaQuery({
    query: vars.MEDIA_QUERIES.TABLET_MOBILE,
  });

  const onSearched = (args) => {
    setSearchParams(args);
    setSearchModalShow(true);
  };

  const { banner } = props;
  const bannerUrl = contentsUrl + banner.image01ISfile;

  return (
    <Fragment>
      <section
        className="p-0"
        style={{ position: "relative" }}
        id="headSectionWithBanner"
      >
        <Slider className="slide-1 home-slider no-arrow">
          <MasterBanner
            title1={props.title1}
            bannerUrl={bannerUrl}
            title={banner.contentISsmallplaintextbox}
            classes="p-center text-center"
          />
        </Slider>

        {searchBar && !isTabletOrMobile && (
          <div
            style={{
              position: "absolute",
              bottom: -40,
              background: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                background: "#fff",
                minWidth: "80%",
                borderRadius: 3,
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
              }}
            >
              <div className="onhover-div" style={{ padding: "10px 15px" }}>
                <SearchHeader
                  onSearch={onSearched}
                  searchFieldCustomStyles={{
                    border: "1px solid #ccc",
                    borderRadius: 4,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </section>

      {searchBar && (
        <Suspense fallback={<div>Loading search overlay...</div>}>
          <SearchOverlay
            isShow={searchModalShow}
            onToggle={() => setSearchModalShow(!searchModalShow)}
            searchParams={searchParams}
          />
        </Suspense>
      )}
    </Fragment>
  );
};

export default Banner;
